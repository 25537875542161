(function () {
  var old = console.log;
  var logger = document.getElementById('log');
  if(logger){
    console.log = function (message) {
      if (typeof message == 'object') {
        logger.innerHTML += (JSON && JSON.stringify ? JSON.stringify(message) : message) + '<br />';
      } else {
        logger.innerHTML += message + '<br />';
      }
    }

    console.error = function (message) {
      if (typeof message == 'object') {
        logger.innerHTML += (JSON && JSON.stringify ? JSON.stringify(message) : message) + '<br />';
      } else {
        logger.innerHTML += message + '<br />';
      }
    }

    console.exception = function (message) {
      if (typeof message == 'object') {
        logger.innerHTML += (JSON && JSON.stringify ? JSON.stringify(message) : message) + '<br />';
      } else {
        logger.innerHTML += message + '<br />';
      }
    }
  }

})();

console.log('ssss');
import './src/scss/custom/fonts/_fonts.scss';

import moment from 'moment';
window.moment = moment;
import 'moment/locale/sk';
//moment.locale('sk');

window.$ = window.jQuery = require("./node_modules/jquery/dist/jquery.js");

require('./node_modules/jquery-ui/jquery-ui.min.js');

require('./node_modules/bootstrap/dist/js/bootstrap.bundle.js');
require('./node_modules/jquery-slimscroll/jquery.slimscroll.js');
window.Waves = require('./node_modules/node-waves/dist/waves.js');
require('./node_modules/metismenu/dist/metisMenu.js');
require('./node_modules/waypoints/lib/jquery.waypoints.min.js');
require('./node_modules/jquery.counterup/jquery.counterup.min.js');

// jquery.toast
require('./node_modules/jquery-toast-plugin/dist/jquery.toast.min.js');
import './node_modules/jquery-toast-plugin/dist/jquery.toast.min.css';

//jquery.mask.js
require('./node_modules/jquery-mask-plugin/src/jquery.mask.js');

// dataTables
//require('./node_modules/datatables.net/js/jquery.dataTables.min.js');
//require('./node_modules/datatables.net-bs4/js/dataTables.bootstrap4.js');
//require('./node_modules/datatables.net-responsive/js/dataTables.responsive.min.js');
//require('./node_modules/datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js');
//require('./node_modules/datatables.net-buttons/js/dataTables.buttons.min.js');
//require('./node_modules/datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js');
//require('./node_modules/datatables.net-buttons/js/buttons.html5.min.js');
//require('./node_modules/datatables.net-buttons/js/buttons.flash.min.js');
//require('./node_modules/datatables.net-buttons/js/buttons.print.min.js');
//require('./node_modules/datatables.net-keytable/js/dataTables.keyTable.min.js');
//require('./node_modules/datatables.net-select/js/dataTables.select.min.js');
import './node_modules/datatables.net-bs4/css/dataTables.bootstrap4.css';
import './node_modules/datatables.net-responsive-bs4/css/responsive.bootstrap4.css';
import './node_modules/datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
//import './node_modules/datatables.net-select-bs4/css/select.bootstrap4.css';

//const axios = require('axios').default;
import axios from './node_modules/axios/dist/axios.min.js';
window.axios = axios;

// Select2
import select2 from  './node_modules/select2/dist/js/select2.full.min.js';
import './node_modules/select2/dist/css/select2.min.css';
select2($);
window.select2 = select2;

// bootstrap-datetimepicker
$.fn.datetimepicker = require('./node_modules/bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min.js');
//$.fn.datetimepicker = require('./node_modules/bootstrap4-datetimepicker/src/js/bootstrap-datetimepicker.js');
import './node_modules/bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

// custombox
//import Custombox from './node_modules/custombox/dist/custombox.min';
//window.Custombox = CustomNetteForms;
//import './node_modules/custombox/dist/custombox.min.css';

// bootstrap-colorpicker
//require('./node_modules/bootstrap-colorpicker/dist/js/bootstrap-colorpicker.min.js');
//import './node_modules/bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css';

// theme scss
import './src/scss/bootstrap.scss';
import './src/scss/icons.scss';
import './src/scss/app.scss';

// nette ajax
require('./../../globalAssets/nette.ajax/nette.ajax.js');
require('./../../globalAssets/init.nette.ajax.js');

// twitterBootstrapTabsRemember
require('./../../globalAssets/twitterBootstrapTabsRemember.js');

// dependentSelectBox
window.dependentSelectBox = require('./../../../../vendor/nasext/dependent-select-box/client-side/dependentSelectBox.js');

// suggestionInput
window.suggestionInput = require('./../../../../vendor/nasext/suggestion-input/client-side/suggestionInput.js');
import './nas/suggestionInput.css';

// CustomNetteForms
import CustomNetteForms from './nas/js/custom.netteForms.js';
window.CustomNetteForms = CustomNetteForms;
window.Nette = CustomNetteForms.getNette();

require('./nas/js/confirm.dialog.js');
require('./nas/js/spinner.ajax.js');

//require('./nas/js/selectOnChangeSubmitForm.js');
require('./../../globalAssets/mceEditor.js');
//require('./nas/js/nas.responsiveFileManager.js');

// nestable
require('./nas/js/jquery.nestable.min.js');
require('./nas/js/nestable.init.js');

// editableText
require('./nas/js/editableText/editableText.js');
require('./nas/js/editableText/editableText.css');

// init
require('./nas/js/init.js'); // theme app
require('./nas/js/nas-init.js');

//Clinic wallPagination
require('./../FrontModule/ClinicWallModule/assets/clinicWallTableControl.js');

// DMS wallPagination
require('./../FrontModule/DmsModule/assets/dmsWallTableControl.js');


// custom scss
import './nas/scss/global-custom.scss';


