//import axios from 'axios';
import Vue from './../../../assets/node_modules/vue/dist/vue.min.js';

Vue.config.devtools = true;

let elementExists = document.getElementById('clinic-wall-table-control');
if (elementExists) {
  const app = new Vue({
    el: '#clinic-wall-table-control',
    data: {
      items: [],
      timer: '',
      date: '',
      endpointUrl: '',
    },
    beforeMount() {
      var init = JSON.parse(this.$el.getAttribute('data-init'));
      for (var index in init) {
        this[index] = init[index];
      }
    },
    mounted() {
      this.calculateSize();
      this.fetchData();
      this.timer = setInterval(this.fetchData, 30000);
    },
    updated() {
      var inner = $('.inner');
      var delay = 8000;
      var durationSize = 11.328527291;
      var duration = inner.innerHeight() * durationSize;

      function scrollDown() {
        inner.switchClass('top', 'bottom', duration, 'swing',
            setTimeout(scrollTop, delay));
      }

      function scrollTop() {
        inner.switchClass('bottom', 'top', duration, 'swing',
            setTimeout(scrollDown, delay));
      }

      scrollDown();
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods: {
      calculateSize() {
        $(window).resize(function() {
          var pageContent = $('.page-content');
          var pageHeader = $('.page-header');

          $('.outer').
              css({
                height: (pageContent.height() - pageHeader.outerHeight()),
              });
        }).resize();
      },
      fetchData() {
        window.axios.get(this.endpointUrl).then(response => {
          this.items = response.data.items;
          this.date = response.data.date;
        });
      },
      isEmpty: function(items) {
        return jQuery.isEmptyObject(items);
      },
    },
  });
}