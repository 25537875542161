(function($, undefined) {

  $.nette.ext({
    init: function() {
      let that = this;
      // show active tab
      $('[data-tab-remember]').each(function() {
        var tabSlug = $(this).data('tabRemember');
        let active = that.getTabValue(tabSlug);

        if (active) {
          $(this).find('a[data-toggle=tab][href="' + active + '"]').tab('show');
        }
      });
    }, load: function() {
      let that = this;

      $('[data-tab-remember]').each(function() {
        let tabSlug = $(this).data('tabRemember');

        $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
          // save to storage
          let tabId = $(e.target).attr('href');
          that.setCookiesStorage(tabSlug, tabId);
        });
      });
    },
  }, {
    getTabValue: function(tabSlug) {
      let cookiesStorage = this.getCookiesStorage();

      if (
          cookiesStorage[window.location.pathname] !== undefined &&
          cookiesStorage[window.location.pathname][tabSlug] !== undefined
      ) {
        let value = cookiesStorage[window.location.pathname][tabSlug];
        return value;
      }

      return null;
    },
    getCookiesStorage: function() {
      let retrievedObject = localStorage.getItem(this.opts.storageName);
      if (retrievedObject === null) {
        return {};
      } else {
        return JSON.parse(retrievedObject);
      }
    },
    setCookiesStorage: function(tabSlug, tabId) {
      let cookiesStorage = this.getCookiesStorage();
      let location = cookiesStorage[window.location.pathname];

      if (location === undefined) {
        location = {};
        location[tabSlug] = tabId;
      } else {
        location[tabSlug] = tabId;
      }

      cookiesStorage[window.location.pathname] = location;
      localStorage.setItem(this.opts.storageName,
          JSON.stringify(cookiesStorage));
    },
    opts: {
      storageName: 'tabRemember',
    },
  });

})(jQuery);
