(function($, undefined) {

  $.nette.ext('spinner', {
    start: function(jqXHR, settings) {
      $('#loader').show(this.speed);
    },
    complete: function() {
      $('#loader').hide(this.speed);
    },
  });
})(jQuery);
