(function($, undefined) {
  $.nette.ext({
    load: function() {
      // Select2
      $('.select2').each(function() {
        var init = {
          templateResult: function (data, container) {
            if (data.element) {
              let disabled = $(data.element).attr("data-disabled-option");
              if(disabled != undefined){
                $(container).attr("data-disabled-option", disabled);
              }
            }
            return data.text;
          }
        };
        $(this).select2(init);
      });

      // fix nette toggle change event
      $('.select2').on('change', function(e) {
        var form = $(this).closest('form');
        Nette.toggleForm(form[0], this);
      });

      $('[data-auto-open-modal]').
          modal({'backdrop': 'static', 'keyboard': false}).
          show();

      // dependentSelectBox
      $('[data-dependentselectbox]').dependentSelectBox();

      // suggestionInput
      $('[data-suggestion-input]').suggestionInput({
        startSuggest: 1,
      });

      $('.input-mask-mac').mask('FF:FF:FF:FF:FF:FF', {
        placeholder: '__:__:__:__:__:__',
        translation: {
          F: {pattern: /[a-f0-9]/},
        },
      });

      //initializing tooltip
      $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip();

      //initializing popover
      $.fn.popover && $('[data-toggle="popover"]').popover({
        html: true,
        trigger: 'hover',
      });

      // data-copy-to-clipboard
      $('[data-copy-to-clipboard]').on('click', function () {
        var $text = $(this).text();
        // Create a "hidden" input
        var aux = document.createElement("input");

        // Assign it the value of the specified element
        aux.setAttribute("value", $text);

        // Append it to the body
        document.body.appendChild(aux);

        // Highlight its content
        aux.select();

        // Copy the highlighted text
        document.execCommand("copy");

        // Remove it from the body
        document.body.removeChild(aux);
      });

      // double-tr-hover
      var isMouseover = false;
      $(document).ready(function() {
        $('[data-double-tr]').on('mouseenter', function() {
          var hoveredValue = $(this).attr('data-double-tr');
          if (!isMouseover) {
            isMouseover = true;
            var x = $(this).closest('table').find('[data-double-tr=\'' + hoveredValue + '\']').not(this).addClass('hover');
          }
        }).on('mouseleave', function() {
          isMouseover = false;
          var hoveredValue = $(this).attr('data-double-tr');
          $(this).closest('table').find('[data-double-tr=\'' + hoveredValue + '\']').not(this).removeClass('hover');
        });
      });


      // notification menu
      /*$('.counter-dropdown-menu').click(function(e) {
        e.stopPropagation();
      });*/

      // counter-combination
      /*$('.counter-combination').each(function() {
        var $this = this;

        $($this).
            closest('.category-container').
            find('input:checkbox').
            on('change', function(e) {
              var items = $($this).
                  closest('.category-container').
                  find('input:checkbox:checked');

              if (items.length > 1) {
                $($this).removeClass('disabled');
              } else {
                $($this).addClass('disabled');
              }
            });

        $($this).off('click').on('click', function(e) {
          e.preventDefault();

          if (!$(this).hasClass('disabled')) {

            var data = [];
            $($this).
                closest('.category-container').
                find('input:checkbox:checked').each(function() {
              data.push($(this).val());

            });

            $.nette.ajax({
              type: 'GET',
              url: $(this).attr('href'),
              data: {
                'items': data,
              },
            });
          }

        });
      });*/

      // custom nette forms
      let customNetteForms = new CustomNetteForms({
        hideOnMouseUp: true,
      });

      // items-per-page
      $('select[data-items-per-page]').off('change');
      $('select[data-items-per-page]').on('change', function(e) {
        e.preventDefault();
        $(this).closest('form').submit();
      });

      // bootstrap-datetimepicker
      $('input.date, input.datetime-local').each(function(i, el) {
        el = $(el);
        el.get(0).type = 'text';
        el.attr('autocomplete', 'off');

        var init = {
          icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'fas fa-calendar-check',
            clear: 'far fa-trash-alt',
            close: 'far fa-times-circle',
          },
          //minDate: el.attr('min'),
          //maxDate: el.attr('max'),
          //weekStart: 1,
          // minView: el.is('.date') ? 'month' : 'hour', -- viewMode
          //viewMode: 'years',
          format: el.is('.date') ? 'DD.MM.YYYY' : 'DD.MM.YYYY - HH:mm', // for seconds support use 'd. m. yyyy - hh:ii:ss' // - DD.MM.YYYY - HH:mm
          //autoclose: true,
          // todayBtn: true,
          //debug: true,
          showClose: true,
          locale: moment.locale(),
          date: el.val(),
        };

        var data = el.data('dateTime');
        if (data != undefined) {
          $.extend(init, data);
        }

        el.datetimepicker(init).next().on('click', function() {
          $(this).prev().focus();
        });

        //monitorEvents(document.getElementById('frm-requisitionAdd-form-departureDateTime'));
        // fix because not catch event cahnge or dp.change
        el.on("blur", function (e) {
          $(this).change();
        });

        // bootstrap-datetimepicker picker custom buttoms
        el.nextAll('[data-set-time]').on('click', function() {
          el.data('DateTimePicker').date($(this).data('setTime'));
        });

      });

      // send requestion to email
      $('[data-requisition-email]').each(function () {
        $(this).on('click', function (e) {
          e.stopPropagation();
          e.preventDefault();
          var emailInput = $(this).data('requisitionEmail');
          var link = $(this).attr('href');
          var email = $(emailInput).val();

          link = link.replace("emailParam", email);
          window.location.href = link;

        });
      });

      // download document
      $('[data-download-document]').click(function(event) {
        event.preventDefault();
        let $link = $(this).attr('href');
        let $downloadLink = $(this).data('download-document');
        $.nette.ajax({
          url: $link,
          complete: function() {
            window.location.href = $downloadLink;
          },
        });
      });


    },
  });

})(jQuery);
