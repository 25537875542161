/*!
 * @author Dusan Hudak <admin@dusan-hudak.com>
 * @license MIT
 */
(function($, undefined) {
  $.nette.ext('mceEditor', {
    load: function() {

      try{ CKEDITOR; }
      catch(e) {
        if(e.name == "ReferenceError") {
          //console.log('CKEDITOR not loaded.');
          return;
        }
      }

      CKEDITOR.config.fillEmptyBlocks = false;
      //CKEDITOR.config.ignoreEmptyParagraph = false;
      //CKEDITOR.config.autoUpdateElement = false;

      // http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-allowedContent
      CKEDITOR.config.allowedContent = true;
      // This allows any class and any inline style.
      CKEDITOR.config.extraAllowedContent = '*(*);*{*}';

      // https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-fullPage
      //CKEDITOR.config.fullPage = true;

      // disable remove all empty tags
      $.each(CKEDITOR.dtd.$removeEmpty, function(i, value) {
        CKEDITOR.dtd.$removeEmpty[i] = false;
      });

      $('[data-mce-editor]').each(function() {

        var allowedFullPage = false;
        if ($(this).data('fullPage') != undefined) {
          allowedFullPage = true;
        }

        // Enable local "codemirror" plugin https://github.com/w8tcha/CKEditor-CodeMirror-Plugin/tree/v1.14
        CKEDITOR.plugins.addExternal('codemirror', '/ckeditor/external-plugins/codemirror/', 'plugin.js');

        var editor = CKEDITOR.instances[$(this).attr('id')];

        // replace when exist
        if (editor) {
          editor.destroy(true);
        }

        //if (editor === undefined) {
        var editor = CKEDITOR.replace($(this).attr('id'), {
          filebrowserBrowseUrl: typeof(filebrowserBrowseUrl) != 'undefined' ? filebrowserBrowseUrl : null,
          filebrowserUploadUrl: typeof(filebrowserUploadUrl) != 'undefined' ? filebrowserUploadUrl : null,
          filebrowserImageBrowseUrl: typeof(filebrowserImageBrowseUrl) != 'undefined' ? filebrowserImageBrowseUrl : null,

          //http://docs.ckeditor.com/#!/guide/dev_configuration
          //customConfig: '/custom/ckeditor_config.js',

          // http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-startupOutlineBlocks
          startupOutlineBlocks: true,

          fullPage: allowedFullPage,

          //http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-extraPlugins
          //http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-plugins
          extraPlugins: 'codemirror',
          removePlugins: 'sourcearea',

          //fillEmptyBlocks:false,/////////////////////////

          //ignoreEmptyParagraph: true,
          //protectedSource: "/<a[^>]*><\/a>/g",
          //autoUpdateElement: false,

          // http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-allowedContent
          //allowedContent: true,
          //extraAllowedContent:  'div(*){*}[*]; ol li span a(*){*}[*]',

          //allowedContent: true,
          //extraAllowedContent: '*(*)',

          //autoUpdateElement: false,
          //allowedContent: true,
          //extraAllowedContent: '*(*);*{*}',

          // http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-entities_latin
          entities_greek: false,
          entities_latin: false,
          basicEntities: false,
          entities_additional: '',
          toolbar: [
            {
              name: 'document',
              items: ['Source', '-', 'Print', '-', 'Templates'],
            },
            {
              name: 'clipboard',
              items: [
                'Cut',
                'Copy',
                'Paste',
                'PasteText',
                'PasteFromWord',
                '-',
                'Undo',
                'Redo'],
            },
            {
              name: 'editing',
              items: ['Find', 'Replace', '-', 'SelectAll'],
            },
            '/',
            {
              name: 'basicstyles',
              items: [
                'Bold',
                'Italic',
                'Underline',
                'Strike',
                'Subscript',
                'Superscript',
                '-',
                'CopyFormatting',
                'RemoveFormat'],
            },
            {
              name: 'paragraph',
              items: [
                'NumberedList',
                'BulletedList',
                '-',
                'Outdent',
                'Indent',
                '-',
                'Blockquote',
                'CreateDiv',
                '-',
                'JustifyLeft',
                'JustifyCenter',
                'JustifyRight',
                'JustifyBlock',
                '-',
                'BidiLtr',
                'BidiRtl'],
            },
            {
              name: 'links',
              items: ['Link', 'Unlink', 'Anchor'],
            },
            {
              name: 'insert',
              items: [
                'Image',
                'Flash',
                'Table',
                'HorizontalRule',
                'SpecialChar',
                'Iframe'],
            },
            {name: 'styles', items: ['Styles', 'Format']},
            {name: 'colors', items: ['TextColor', 'BGColor']},
            {name: 'tools', items: ['Maximize', 'ShowBlocks']},
          ],

        });
        //}

        /**
         * Configure code
         * http://sdk.ckeditor.com/samples/htmlformatting.html
         * http://docs.ckeditor.com/#!/guide/dev_output_format
         */
        editor.on('instanceReady', function() {
          // Output self-closing tags the HTML4 way, like <br>.
          this.dataProcessor.writer.selfClosingEnd = '>';

          // Use line breaks for block elements, tables, and lists.
          var dtd = CKEDITOR.dtd;

          for (var e in CKEDITOR.tools.extend(
              {},
              dtd.$nonBodyContent,
              dtd.$block,
              dtd.$listItem,
              dtd.$tableContent
          )) {
            this.dataProcessor.writer.setRules(e, {
              indent: true,
              breakBeforeOpen: true,
              breakAfterOpen: true,
              breakBeforeClose: true,
              breakAfterClose: true,
            });
          }
          // Start in source mode.
          //this.setMode('source');
        });

        // update textarea
        editor.on('change', function() {
          editor.updateElement();
        });

      });

    },
  });
})(jQuery);
