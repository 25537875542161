import Nette
  from './../../../../../../vendor/nette/forms/src/assets/netteForms.js';

export default class CustomNetteForms {

  constructor(options) {
    this.options = {
      hideOnMouseUp: true,
      dataSelector: 'data-form-alert-for',
    };

    this.options = $.extend({}, this.options, options);
    let that = this;

    Nette.showFormErrors = function(form, errors) {
      let messages = [];
      let focusElem;
      that.resetAll();

      for (var i = 0; i < errors.length; i++) {
        var elem = errors[i].element,
            message = errors[i].message;

        //console.log([elem, message]);

        that.addError(elem, message);
        if (!focusElem && elem.focus) {
          focusElem = elem;
          focusElem.focus();
        }
      }
    };

    Nette.initOnLoad();
    this.processError();
  }

  static getNette(){
    return Nette;
  }

  resetAll() {
    let list = document.querySelectorAll('[' + this.options.dataSelector + ']');

    list.forEach(el => {
      var selector = el.getAttribute(this.options.dataSelector);
      var elm = document.querySelector('#' + selector);
      this.reset(elm);
    });
  }

  processError() {
    let focusElem;
    let list = document.querySelectorAll('[' + this.options.dataSelector + ']');

    list.forEach(el => {

      var selector = el.getAttribute(this.options.dataSelector);
      var elem = document.querySelector('#' + selector);

      if (!focusElem && elem.focus) {
        focusElem = elem;
        focusElem.focus();
      }

      elem.classList.add('is-invalid');
      this.hideOnAction(elem);

    });
  };

  addError(elem, message) {
    this.reset(elem);
    this.render(elem, message);
    this.hideOnAction(elem);
  };

  reset(elem) {
    let a = document.querySelector('#' + elem.id);

    if (a) {
      a.classList.remove('is-invalid');
    }

    let b = document.querySelector(
        '[' + this.options.dataSelector + '="' + elem.id + '"]',
    );

    if (b) {
      b.remove();
    }
  };

  render(elem, message) {
    let messageBox = document.createElement('div');
    messageBox.classList.add('invalid-tooltip');
    messageBox.setAttribute(this.options.dataSelector, elem.id);
    messageBox.innerHTML = message;

    let node = document.querySelector('#' + elem.id);
    node.parentNode.appendChild(messageBox);
    node.classList.add('is-invalid');
  };

  hideOnAction(elem) {
    let $this= this;
    if (this.options.hideOnMouseUp) {
      let node = document.querySelector('#' + elem.id);

      // not work, I do not know why
      /*document.getElementById(elem.id).addEventListener('change', function (e) {
        console.log(elem);
        $this.reset(elem);
      });*/

      $('#' + elem.id).on('change', function(e) {
        $this.reset(elem);
      });

    }
  };

}
