//import axios from 'axios';
import Vue from './../../../assets/node_modules/vue/dist/vue.min.js';

try {
  let elementExists = document.getElementById('dms-table-control');
  if (elementExists) {

    Vue.config.devtools = true;
    const app = new Vue({
      el: '#dms-table-control',
      data: {
        items: [],
        content: null,
        timer: '',
        date: '',
        endpointUrl: '',
        timeToView: 0,
        timeToViewCounter: 0,
        totalCount: 0,
        page: 0,
        realPage: 0,
        title: '',
      },
      beforeMount() {
        var init = JSON.parse(this.$el.getAttribute('data-init'));
        for (var index in init) {
          this[index] = init[index];
        }
      },
      mounted() {
        let $this = this;
        $this.fetchData(this.page);
        $this.calculateSize();

        let downCounter = function() {
          let inner = $('.inner');
          let outer = $('.outer');
          let title = $('.title');

          if ($this.timeToViewCounter == $this.timeToView) {
            inner.removeClass('lightInner');
            title.removeClass('lightInner');
            outer.removeClass('lightInner');

            if ((outer.height() - inner.height()) < 0) {
              scrollDown($this.timeToView);
            }
          }

          $this.timeToViewCounter--;

          if ($this.timeToViewCounter <= 1) {

          }

          if ($this.timeToViewCounter <= 1) {

            inner.delay().addClass('lightInner');
            title.delay().addClass('lightInner');
            outer.delay().addClass('lightInner');
          }

          if ($this.timeToViewCounter == 0) {
            let page = ($this.page + 1);
            if (page == $this.totalCount || $this.totalCount == 0) {
              page = 0;
            }

            $this.fetchData(page);

            inner.switchClass('bottom', 'top', 0, 'swing');

            clearInterval($this.timer);

            $this.timer = setInterval(function() {
              downCounter();
            }, 1000);
          }
        };

        function scrollDown(timeToView) {
          let inner = $('.inner');
          let topWait = 5;
          let bottomWait = 5;

          timeToView = (timeToView - topWait - bottomWait);
          inner.delay((1000 * topWait)).switchClass(
              'top',
              'bottom',
              (1000 * timeToView),
              'swing',
          );
        }

        $this.timer = setInterval(function() {
          downCounter();
        }, 1000);
      },
      beforeDestroy() {
        clearInterval(this.timer);
      },
      methods: {
        calculateSize() {
          $(window).resize(function() {
            var pageContent = $('.page-content');
            var pageHeader = $('.page-header');

            $('.outer').
                css({
                  height: (pageContent.height() - pageHeader.outerHeight() -
                      20),
                });
          }).resize();
        },
        fetchData(page) {
          let endpointUrl = this.endpointUrl + '&page=' + page;

          window.axios.get(endpointUrl).then(response => {
            this.items = response.data.items;
            this.date = response.data.date;
            this.content = response.data.content;
            this.totalCount = response.data.totalCount;
            this.title = response.data.title;

            let timeToView = 15;
            if (response.data.timeToView !== null) {
              timeToView = response.data.timeToView;
            }
            this.timeToView = timeToView;
            this.timeToViewCounter = timeToView;

            let page = parseInt(response.data.page);
            this.page = page;
            this.realPage = (page + 1);
          });
        },
      },
    });
  } else {
    console.log('Element not found');
  }
} catch (err) {
  console.log(err.message);
}

